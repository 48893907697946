
.content {
    margin-bottom: 20px;
}

.panel-wrapper {
    margin: 10px 0 0 0;
    padding: 10px 20px;
    background-color: var(--panel-bg);
    border-top: var(--panel-border-top);
    position: relative;     /* for save button positioning */
}
.panel-wrapper.panel-closed .panel-content {
    display: none;
}
.panel-wrapper:last-child {
    border-bottom: none;
}
.panel-wrapper.panel-closed {
    padding-bottom: 0;
}

.panel-title {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem;
    font-weight: var(--panel-font-weight);
    color: var(--panel-font-color);
    border-left: none;
    border-right: none;
}

.panel-title svg {
    height: 20px;
    fill: #999;
    margin-left: 0;
}

.space-before-top {
    margin-top: 20px;
}

.instruction {
    font-size: 12px;
    color: var(--text-light);
}
.help-text {
    font-size: 12px;
    color: var(--text-light);
}

.row {
    display: flex;
    flex-direction: row;
    margin: 5px 0;
}

.row.aligned {
    align-items: center;
}

.h-aligned {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.values-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.values-row > * {
    margin-right: 10px;
}

.left-space {
    margin-left: 5px;
}
.left-space-double {
    margin-left: 10px;
}

.warning {
    color: red;
}

label {
    color: var(--text-light);
    padding: 1px 3px 1px 2px;
}

label.checked {
    color: var(--text-checked);
    border-radius: 4px;
}

h1 {
    margin-bottom: 10px;
}

h2 {
    margin-bottom: 5px;
}

h3, h4 {
    margin: 0.5rem 0;
}

.panel-toggle {
    cursor: pointer;
    /*padding-bottom: 1px;*/
    /*border-bottom: 2px solid #b8b8b8;*/
    user-select: none;
    font-weight: 700;
    font-size: 125%;
    color: var(--panel-toggle-text);
}

.panel-closed .panel-toggle {
    border-bottom: none;
    padding-bottom: 5px;
}

.content-wrapper {          /* TODO: DELETE ME (if not used) */
    margin-top: 5px;
}

.show-hide {
    float: right;
    font-size: 14px;
    margin-top: 0.5em;
}

.title-info {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 400;
    color: brown;
}

.float-info {
    float: right;
    background-color: #fbf9e9;
    border: 1px solid #e3cf57;
    color: #212121;
    font-size: 14px;
}

.float-right {
    float: right;
    margin-right: 10px;
    padding: 0;
}

.float-title {
    background-color: #d1d1d1;
    padding: 2px 5px;
}

div.float-row {
    margin: 0;
    padding: 5px;
}

.box {
    padding: 0;
    background-color: #b8b8b8;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
}

.box-title {
    background-color: #d1d1d1;
    padding: 2px 5px;
}

.grid-2x2 {
    margin: 0 10px;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    justify-content: start;
    align-items: center;
}

.col-span-2 {
    grid-column-end: span 2;
}

.col-span-3 {
    grid-column-end: span 3;
}

.col-span-4 {
    grid-column-end: span 4;
}

.warn {
    color: black;
    background-color: darkorange;
    padding: 4px;

}


select:not([multiple]) {
    font-size: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    /*padding: 0.25rem 1.5rem 0.25rem 0.5rem;*/
    /*padding: 2px 1.5rem 2px 4px;*/
    padding: 1px 1.5rem 1px 4px;
    /*padding: .5em;*/
    /*padding-right: 1.5em*/
    border-radius: 0;
}

/*
select {
    font-size: 14px;
}
*/

input[type="checkbox"],
input[type="radio"] {
    margin-right: 4px;
}

input[type="text"] {
    padding: 0 2px;
}


.midi-progress {
    /*margin-top: 5px;*/
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;
    /*padding: 2px 10px 1px 10px;*/
    padding: 0;
    color: red;
}

.midi-progress.reading {
    /*background-color: var(--yellow);   !* same color as read button *!*/
    /*border: 1px solid #999;*/
}

.midi-progress.writing {
    /*background-color: var(--red);   !* same color as write button *!*/
    /*color: #fff;*/
}

.midi-progress.done {
    animation: hide-animation 0.7s ease-in 2s forwards;
}

@keyframes hide-animation {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

