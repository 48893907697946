.action-name.exp {
    grid-column-end: span 2;
}

.action-name-name.exp {
}

.action-label.exp {
    align-self: start;
    display: flex;
    flex-direction: row;
}
