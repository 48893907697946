.button-save-button {
    position: absolute;
    /*top: -5px;*/
    /*left: calc(100% + 10px);*/
    top: 10px;
    right: 20px;
    /*text-align: right;*/
    display: flex;
    align-items: flex-start;
}

.button-save-button .help {
    /*margin-top: 5px;*/
    margin-right: 5px;
    font-size: 13px;
    color: var(--text-extra-light);
    /*background-color: #fffdbb;*/
    background-color: #f8f8f8;
    padding: 1px 4px;
}


.button-global {
    margin-top: 10px;
    /*
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 20px;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 135px auto;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    justify-content: start;
    align-items: center;
    */
}

.button-global svg {
    height: 1rem;
    background-color: transparent;
}

.button-global > div {
    margin: 5px 0;
}

.save-reminder {
    border-top: 1px solid #ff8585;
    /*border-bottom: 1px solid #ff9999;*/
    /*background-color: #ecebc9;*/
    background-color: #fffdbb;
    padding: 5px;
    /*border-radius: 5px;*/
    font-size: 12px;
    color: #555;
}