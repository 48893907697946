
.grid-7x2,
.grid-12x2 {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 3px;
    justify-content: start;
    align-items: start;
    position: relative;
}
.grid-7x2 {
    grid-template-rows: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.grid-12x2 {
    grid-template-rows: 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.multijack > div {
    margin: 4px 0;
}

.float-description {
    position: absolute;
    right: 0px;
    font-size: 14px;
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 5px 10px;
    color: #333;
}

.descr-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.float-description > ul {
    margin: 5px 0;
    padding: 0 0 0 20px;
}

.float-description li {
    margin-bottom: 3px;
}

.model-chooser > div {
    margin-bottom: 2px;
    color: var(--text-light);
}

.global-information {
    position: absolute;
    z-index: 1000;
    max-width: 500px;
    right: 20px;
    top: 20px;
    border: none;
    border-radius: 3px;
    background-color: #ffff77;
    padding: 10px;
    color: #555;
}

.global-information.connection-help div {
    margin: 10px 0;
}

.global-information.connection-help div:first-child {
    margin-top: 0;
}

.global-information .dismiss-action {
    text-align: right;
    margin-top: 10px;
}
