
html,
body {
  height: 100%;
}

html {
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  color: var(--text);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-bg);;
}

html {
  background-color: var(--main-bg-color); /* because #root element may become larger thant body element */
}

#root {
  padding: 2px;
  max-width: 1200px;
  margin: 0 auto;
}

body.popup {
  background-color: #fff;
}

.popup #root {
  background-color: white;
}

:focus { outline: none; }

.b {
  font-weight: 500;
}

.bold {
  font-weight: 500;
}

.i {
  font-style: italic;
}

input, label {
  cursor: pointer;
}

@media print {

  button {
    display: none!important;
  }

}

