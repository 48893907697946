body.print {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.2;
    color: #000;
    margin: 20px 40px;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
}

.print #root {
    background-color: white;
}

.print-title {
    font-size: 20pt;
}

.print-title-help {
    color: #999;
    font-size: 10pt;
}

.print .panel-wrapper {
    padding: 0;
    border: none;
    background-color: white;
}

.print .panel-title {
    display: block;
    margin: 0.75cm 0 0.5cm 0;
    padding: 0;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #000;
    font-size: 1.5rem;
}

.print .content-wrapper {
    margin: 0;
}

@media print {

    @page {
        margin: 2cm 1cm;
    }

    body {
        margin: 0!important;
        padding: 0!important;
        font-size: 10pt;
    }

    .button-editor {
        page-break-inside: avoid;
    }

    .print-title {
        color: #000;
    }

    .print-title-help {
        display: none;
    }

    .panel-wrapper {
        background-color: white;
    }

}

