
.ports-grid {
    display: flex;
    flex-direction: row;
}

.port {
    margin-right: 10px;
    padding: 6px 4px 5px 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--midi-port-bg);
    font-size: 13px;
    color: var(--text-light);
}

.port > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.port-name {
    font-weight: 500;
}

.port-switch {
    margin-left: 4px;
}

.react-switch {
    vertical-align: middle;
}

.react-switch-bg {
    background-color: rgb(136, 136, 136)!important;
}

.port.device-ok {
    background-color: var(--midi-port-ok-bg);
    color: var(--text);
}

.device-ok .react-switch-bg {
    background-color: green!important;
}


.subheader {
    background-color: var(--subheader-bg);
    padding: var(--subheader-padding);
    display: flex;
    flex-direction: row;
    position: relative;
}

.subheader .error {
    font-size: 13px;
    display: flex;
    flex-direction: row;
}
.subheader .error > div {
    background-color: #ff6258;
    padding: 4px 5px 2px 5px;
}

.progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    /*background-color: yellow;*/
    color: red;
}

/*
.progress-bar.reading {
    background-color: var(--yellow);
}
.progress-bar.writing {
    background-color: var(--red);
}
*/

.subheader-left {
    flex-grow: 2;
}

.subheader-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.subheader .instruction {
    font-size: 13px;
    font-weight: 500;
    margin: var(--instruction-margin);
    /*color: #b2b2b2;*/
    color: var(--text-light);
}

.subheader .tip {
    font-size: 13px;
    font-weight: 500;
    margin: var(--instruction-margin);
    color: var(--text-tip);
    background-color: var(--text-tip-bg);
}

.no-midi {
    margin: 0;
    border: 8px solid #e0e0e0;
    padding: 4px;
    background-color: #fff900;
    color: #000;
    text-align: center;
}

@media print {
    .subheader {
        display: none;
    }
}



.pre-header {
    margin-top: 20px;
    margin-bottom: 2px;
    font-size: 12px;
    color: var(--text-light);
    /*text-align: right;*/
    display: flex;
    flex-direction: row;
}

.pre-header button {
    border: none;
    background: none;
    padding: 0;
    color: var(--link);
    margin-right: 10px;
    cursor: pointer;
}

.pre-header a {
    text-decoration: none;
    color: var(--link);
}

.header {
    background-color: var(--header-bg);
    /*border-top: 2px solid #1e85be;*/
    border-top: var(--header-border-top);
    /*margin-bottom: 1px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    /*background-color: #3a3a3a;*/
    /*background-color: #707070;*/
    /*background-color: #eaeaea;*/
    /*padding: 10px 10px;*/
    padding: var(--header-padding);
    /*
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #333;
    padding: 10px 10px 10px 10px;
    border-left: 1px solid var(--border-dark-light);
    border-right: 1px solid var(--border-dark-dark);
    */
}

.header-app-name {
    font-family: "Roboto Condensed", "Roboto", Helvetica, Arial, sans-serif;
    color: #909090;
    text-align: right;
    cursor: pointer;
    font-size: 20px;
    /*
    color: #c7c7c7;
    text-align: right;
    cursor: pointer;
    */
}

.header > button {
    margin-right: 20px;
}

.header-link {
    padding: 14px 0;
    margin: 0;
    cursor: pointer;
}

.header-link:hover {
    background-color: #000;
}

.header-link div {
    padding: 0 16px;
    margin: 0;
    text-decoration: none;
    color: #fff;
}

.header-link.active {
    background-color: #0030ff;
}

.header-link.active a {
    color: #fff;
}

.spacer {
    flex-grow: 10;
}

.popup-content {
    width: 30%;
}

.about {
    padding: 10px 30px;
    text-align: center;
    color: #555;
}

.about a {
    text-decoration: none;
}

.about > div {
    margin-top: 20px;
}

.about-title {
    /*margin-bottom: 20px;*/
    /*font-weight: bold;*/
    font-size: 2rem;
}

.about-deps {
    /*margin-top: 20px;*/
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    padding: 10px;
}

.about-deps > div {
    margin-bottom: 10px;
}

.about-deps > a {
    margin-right: 15px;
}

.about-support {
    /*margin-top: 10px;*/
    /*border-top: 1px solid #aaa;*/
    /*padding: 10px;*/
    margin-bottom: 20px;
}

.grid-7x2,
.grid-12x2 {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 3px;
    justify-content: start;
    align-items: start;
    position: relative;
}
.grid-7x2 {
    grid-template-rows: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.grid-12x2 {
    grid-template-rows: 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.multijack > div {
    margin: 4px 0;
}

.float-description {
    position: absolute;
    right: 0px;
    font-size: 14px;
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 5px 10px;
    color: #333;
}

.descr-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.float-description > ul {
    margin: 5px 0;
    padding: 0 0 0 20px;
}

.float-description li {
    margin-bottom: 3px;
}

.model-chooser > div {
    margin-bottom: 2px;
    color: var(--text-light);
}

.global-information {
    position: absolute;
    z-index: 1000;
    max-width: 500px;
    right: 20px;
    top: 20px;
    border: none;
    border-radius: 3px;
    background-color: #ffff77;
    padding: 10px;
    color: #555;
}

.global-information.connection-help div {
    margin: 10px 0;
}

.global-information.connection-help div:first-child {
    margin-top: 0;
}

.global-information .dismiss-action {
    text-align: right;
    margin-top: 10px;
}

.messages-summary {
    margin-top: 5px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: start;
    grid-row-gap: 3px;
}

.msg-number {
    text-align: right;
    color: #555;
    margin-right: 10px;
}

.msg-type {
    margin-right: 10px;
    font-weight: 500;
}

.msg-description {
    display: flex;
}

.msg-channel {
    margin-right: 10px;
}

.msg-description > div {
    padding: 0 10px 0 0;
}

.message-summary > div {
    margin-right: 5px;
}

.action-message {
    display: flex;
    flex-direction: row;
    margin: 0 0 5px 0;
}

.action-message > div {
    margin-right: 10px;
}


.button-action {
    /*
    padding: 10px 10px 10px 10px;
    margin: 10px 10px 10px 0;
    border-left: 10px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    background-color: #f0f0f0;
    */
    margin-top: 10px;
    border-top: var(--button-action-border-top);
    position: relative;
}

.button-action.last {
    margin-bottom: 0;
}

/*
.button-action.ignored {
    border-color: #e8e8e8;
}
!*0: "off",*!
.button-action.color-0 {
    border-color: #d8d8d8;
}
!*1: "red",*!
.button-action.color-1 {
    border-color: #ff4444;
}
!*2: "green",*!
.button-action.color-2 {
    border-color: green;
}
!*3: "blue",*!
.button-action.color-3 {
    border-color: #8282ff;
}
!*4: "orange",*!
.button-action.color-4 {
    border-color: orange;
}
!*5: "yellow",*!
.button-action.color-5 {
    border-color: yellow;
}
!*6: "cyan",*!
.button-action.color-6 {
    border-color: cyan;
}
!*7: "violet",*!
.button-action.color-7 {
    border-color: violet;
}
!*8: "white"*!
.button-action.color-8 {
    border-color: white;
}
*/

.action-grid-0,     /* tap */
.action-grid-1,     /* hold */
.action-grid-2 { /* long-hold */
    display: grid;
    grid-template-columns: 145px auto;
    align-items: baseline;
}

.action-grid-0 {    /* tap */
    grid-template-rows: repeat(3, auto);
}

.action-grid-1,     /* hold */
.action-grid-2 {    /* long-hold */
    grid-template-rows: repeat(4, auto);
}

.span-2 {
    grid-column-end: span 2;
}

.action-grid-0.edit .action-row,
.action-grid-1.edit .action-row,
.action-grid-2.edit .action-row {
    padding: 2px 0;
}

.action-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 23px;
    /*padding: 2px 0;*/
}

.edit .action-row.action-with-select {
    /*padding: 0;*/
}

.action-row > div {
    margin-right: 5px;
}

.button-action .action-name {
    /*
    grid-column-end: span 2;
    display: grid;
    grid-template-columns: 140px auto 200px;
    margin-bottom: 15px;
    color: #000;
    */
    grid-column-end: span 2;
    margin: 0 0 5px 0;
    display: flex;
    flex-direction: row;
    color: #000;

}

/*
.action-wrapper {
    position: relative;
    border-top: 1px solid #888888;
}
*/

.action-edit-button {
    position: absolute;
    top: 5px;
    right: 0;
}


.action-name-name {
    /*
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 5px;
    padding-top: 2px;
    */
    width: 150px;
    padding: 3px 5px 2px 5px;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--action-name-name-text);
    background-color: var(--action-name-name-bg);
    display: flex;
    align-items: center;

    margin-top: -1px;

}

/*
.instruction {
    color: var(--text-light);
    font-size: 12px;
}
*/

.action-name .instruction {
    /*
    padding-top: 0.35rem;
    padding-bottom: 1px;
    margin-right: 10px;
    padding-left: 5px;
    */
    margin-right: 10px;
    padding-left: 5px;
    display: flex;
    align-items: center;
}



/*
.button-action.color-0 .action-name-name,   !*0: "off",*!
.button-action.color-0 .instruction {
    background-color: #d8d8d8;
}
.button-action.color-1 .action-name-name,   !*1: "red",*!
.button-action.color-1 .instruction {
    background-color: #ff4444;
    color: #fff;
}
.button-action.color-2 .action-name-name,   !*2: "green",*!
.button-action.color-2 .instruction {
    background-color: green;
    color: #fff;
}
.button-action.color-3 .action-name-name,   !*3: "blue",*!
.button-action.color-3 .instruction {
    background-color: #8282ff;
    color: #fff;
}
.button-action.color-4 .action-name-name,   !*4: "orange",*!
.button-action.color-4 .instruction {
    background-color: orange;
}
.button-action.color-5 .action-name-name,   !*5: "yellow",*!
.button-action.color-5 .instruction {
    background-color: yellow;
}
.button-action.color-6 .action-name-name,   !*6: "cyan",*!
.button-action.color-6 .instruction {
    background-color: cyan;
}
.button-action.color-7 .action-name-name,   !*7: "violet",*!
.button-action.color-7 .instruction {
    background-color: violet;
}
.button-action.color-8 .action-name-name,   !*8: "white"*!
.button-action.color-8 .instruction {
    background-color: white;
}
*/



/*
.button-action.color-0 .action-name-name {   !*0: "off",*!
    background-color: #909090;
}
.button-action.color-1 .action-name-name {   !*1: "red",*!
    background-color: #ff4444;
    color: #fff;
}
.button-action.color-2 .action-name-name {   !*2: "green",*!
    background-color: green;
    color: #fff;
}
.button-action.color-3 .action-name-name {   !*3: "blue",*!
    background-color: #8282ff;
    color: #fff;
}
.button-action.color-4 .action-name-name {   !*4: "orange",*!
    background-color: orange;
}
.button-action.color-5 .action-name-name {   !*5: "yellow",*!
    background-color: yellow;
    color: #000;
}
.button-action.color-6 .action-name-name {   !*6: "cyan",*!
    background-color: cyan;
    color: #000;
}
.button-action.color-7 .action-name-name {   !*7: "violet",*!
    background-color: violet;
}
.button-action.color-8 .action-name-name {   !*8: "white"*!
    background-color: white;
    color: #000;
}
*/



span.led-color {
    margin-left: 5px;
    padding: 0 5px;
}
.button-action.color-0 span.led-color {   /*0: "off",*/
    background-color: inherit;
    padding: 0;
}
select.led-color {
    margin-left: 5px;
}
.button-action.color-0 select.led-color {   /*0: "off",*/
    background-color: white;
}
.button-action.color-1 .led-color {   /*1: "red",*/
    background-color: #ff4444;
    color: white;
}
.button-action.color-2 .led-color {   /*2: "green",*/
    background-color: green;
    color: white;
}
.button-action.color-3 .led-color {   /*3: "blue",*/
    background-color: #8282ff;
    color: white;
}
.button-action.color-4 .led-color {   /*4: "orange",*/
    background-color: orange;
}
.button-action.color-5 .led-color {   /*5: "yellow",*/
    background-color: yellow;
    color: #000;
}
.button-action.color-6 .led-color {   /*6: "cyan",*/
    background-color: cyan;
    color: #000;
}
.button-action.color-7 .led-color {   /*7: "violet",*/
    background-color: violet;
}
.button-action.color-8 .led-color {   /*8: "white"*/
    background-color: white;
    color: #000;
}







.button-action.disabled .action-name-name {
    color: #aaa;
}
.button-action .action-name.ignored {
    background-color: #e8e8e8;
}

/*
.button-action .action-name.color-0 {
    background-color: #d8d8d8;
}

.button-action .action-name.color-1 {
    background-color: #ff4444;
    color: #fff;
}
.button-action .action-name.color-1 .instruction {
    color: #eee;
}
.action-name.color-1 > button {
     border-color: #fff;
}

.button-action .action-name.color-2 {
    background-color: green;
    color: #fff;
}
.button-action .action-name.color-2 .instruction {
    color: #eee;
}
.action-name.color-2 > button {
    border-color: #fff;
}

.button-action .action-name.color-3 {
    background-color: #8282ff;
    color: #fff;
}
.button-action .action-name.color-3 .instruction {
    color: #eee;
}
.action-name.color-3 > button {
    border-color: #fff;
}

.button-action .action-name.color-4 {
    background-color: orange;
}

.button-action .action-name.color-5 {
    background-color: yellow;
}

.button-action .action-name.color-6 {
    background-color: cyan;
}

.button-action .action-name.color-7 {
    background-color: violet;
}

.button-action .action-name.color-8 {
    background-color: white;
}

.button-action .action-name button {
    margin-left: 10px;
}
*/

/*
.button-action .action-meta {
    margin-bottom: 15px;
    padding-bottom: 3px;
    border-bottom: 1px solid #999999;
}
*/

.dim {
    color: #777;
}

.index {
    text-align: right;
}

.edit-action-msgs {
    margin: 10px 0;
}

.edit-action-msgs table {
    border-collapse: collapse;
}

.edit-action-msgs table th,
.edit-action-msgs table td {
    border: 1px solid #aaa;
}

.edit-grid {
    /*margin-top: 5px;*/
    display: grid;
    grid-template-columns: repeat(11, auto);
    grid-column-gap: 10px;
    grid-row-gap: 3px;
    justify-content: start;
    /*background-color: #e8e8e8;*/
    padding: 5px;
    border-radius: 6px;
}

.edit-grid .h {
    /*
    font-size: 85%;
    border-bottom: 1px solid #777;
    margin-bottom: 4px;
    */
    color: var(--text-light);
    font-size: 12px;
    border-bottom: 1px solid #888888;
    margin-bottom: 4px;
}

.edit-value-label {
    font-size: 85%;
    margin-right: 10px;
    white-space: nowrap;
}

.edit-value-label.min-space {
    margin-right: 5px;
}

.edit-value-value {
    font-size: 85%;
}

.edit-grid input[type="text"] {
    width: 30px;
}

.edit-data {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.self-align-center {
    align-self: center;
}

select.pcstyle,
select.ccstyle,
select.notes {
    margin-right: 10px;
}

.edit-grid input[type="range"] {
    margin-right: 5px;
}

.button-save-button {
    position: absolute;
    /*top: -5px;*/
    /*left: calc(100% + 10px);*/
    top: 10px;
    right: 20px;
    /*text-align: right;*/
    display: flex;
    align-items: flex-start;
}

.button-save-button .help {
    /*margin-top: 5px;*/
    margin-right: 5px;
    font-size: 13px;
    color: var(--text-extra-light);
    /*background-color: #fffdbb;*/
    background-color: #f8f8f8;
    padding: 1px 4px;
}


.button-global {
    margin-top: 10px;
    /*
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 20px;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 135px auto;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    justify-content: start;
    align-items: center;
    */
}

.button-global svg {
    height: 1rem;
    background-color: transparent;
}

.button-global > div {
    margin: 5px 0;
}

.save-reminder {
    border-top: 1px solid #ff8585;
    /*border-bottom: 1px solid #ff9999;*/
    /*background-color: #ecebc9;*/
    background-color: #fffdbb;
    padding: 5px;
    /*border-radius: 5px;*/
    font-size: 12px;
    color: #555;
}

.edit-grid.exp {
    grid-template-columns: repeat(7, auto);
}

.action-name.exp {
    grid-column-end: span 2;
}

.action-name-name.exp {
}

.action-label.exp {
    align-self: start;
    display: flex;
    flex-direction: row;
}


.content {
    margin-bottom: 20px;
}

.panel-wrapper {
    margin: 10px 0 0 0;
    padding: 10px 20px;
    background-color: var(--panel-bg);
    border-top: var(--panel-border-top);
    position: relative;     /* for save button positioning */
}
.panel-wrapper.panel-closed .panel-content {
    display: none;
}
.panel-wrapper:last-child {
    border-bottom: none;
}
.panel-wrapper.panel-closed {
    padding-bottom: 0;
}

.panel-title {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem;
    font-weight: var(--panel-font-weight);
    color: var(--panel-font-color);
    border-left: none;
    border-right: none;
}

.panel-title svg {
    height: 20px;
    fill: #999;
    margin-left: 0;
}

.space-before-top {
    margin-top: 20px;
}

.instruction {
    font-size: 12px;
    color: var(--text-light);
}
.help-text {
    font-size: 12px;
    color: var(--text-light);
}

.row {
    display: flex;
    flex-direction: row;
    margin: 5px 0;
}

.row.aligned {
    align-items: center;
}

.h-aligned {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.values-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.values-row > * {
    margin-right: 10px;
}

.left-space {
    margin-left: 5px;
}
.left-space-double {
    margin-left: 10px;
}

.warning {
    color: red;
}

label {
    color: var(--text-light);
    padding: 1px 3px 1px 2px;
}

label.checked {
    color: var(--text-checked);
    border-radius: 4px;
}

h1 {
    margin-bottom: 10px;
}

h2 {
    margin-bottom: 5px;
}

h3, h4 {
    margin: 0.5rem 0;
}

.panel-toggle {
    cursor: pointer;
    /*padding-bottom: 1px;*/
    /*border-bottom: 2px solid #b8b8b8;*/
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-weight: 700;
    font-size: 125%;
    color: var(--panel-toggle-text);
}

.panel-closed .panel-toggle {
    border-bottom: none;
    padding-bottom: 5px;
}

.content-wrapper {          /* TODO: DELETE ME (if not used) */
    margin-top: 5px;
}

.show-hide {
    float: right;
    font-size: 14px;
    margin-top: 0.5em;
}

.title-info {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 400;
    color: brown;
}

.float-info {
    float: right;
    background-color: #fbf9e9;
    border: 1px solid #e3cf57;
    color: #212121;
    font-size: 14px;
}

.float-right {
    float: right;
    margin-right: 10px;
    padding: 0;
}

.float-title {
    background-color: #d1d1d1;
    padding: 2px 5px;
}

div.float-row {
    margin: 0;
    padding: 5px;
}

.box {
    padding: 0;
    background-color: #b8b8b8;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
}

.box-title {
    background-color: #d1d1d1;
    padding: 2px 5px;
}

.grid-2x2 {
    margin: 0 10px;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    justify-content: start;
    align-items: center;
}

.col-span-2 {
    grid-column-end: span 2;
}

.col-span-3 {
    grid-column-end: span 3;
}

.col-span-4 {
    grid-column-end: span 4;
}

.warn {
    color: black;
    background-color: darkorange;
    padding: 4px;

}


select:not([multiple]) {
    font-size: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    /*padding: 0.25rem 1.5rem 0.25rem 0.5rem;*/
    /*padding: 2px 1.5rem 2px 4px;*/
    padding: 1px 1.5rem 1px 4px;
    /*padding: .5em;*/
    /*padding-right: 1.5em*/
    border-radius: 0;
}

/*
select {
    font-size: 14px;
}
*/

input[type="checkbox"],
input[type="radio"] {
    margin-right: 4px;
}

input[type="text"] {
    padding: 0 2px;
}


.midi-progress {
    /*margin-top: 5px;*/
    text-align: center;
    font-size: 1.25rem;
    font-weight: 500;
    /*padding: 2px 10px 1px 10px;*/
    padding: 0;
    color: red;
}

.midi-progress.reading {
    /*background-color: var(--yellow);   !* same color as read button *!*/
    /*border: 1px solid #999;*/
}

.midi-progress.writing {
    /*background-color: var(--red);   !* same color as write button *!*/
    /*color: #fff;*/
}

.midi-progress.done {
    -webkit-animation: hide-animation 0.7s ease-in 2s forwards;
            animation: hide-animation 0.7s ease-in 2s forwards;
}

@-webkit-keyframes hide-animation {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes hide-animation {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}


.information {
    margin: 10px 0 0 0;
    padding: 10px 20px;
    border-top: var(--panel-border-top);
    position: relative;     /* for button positioning */
    border: 1px solid #aaa;
    background-color: #ffffaa;
    color: #333333;
    line-height: 150%;
}

.information .sub-info {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--text-extra-light);
    font-size: 13px;
    text-align: right;
}

.information .important {
    font-weight: bold;
    margin-bottom: 5px;
}

.information ol,
.information ul {
    margin: 0;
    margin-bottom: 5px;
}

.information li {
    margin-top: 2px;
}

.information-actions {
    position: absolute;
    right: 20px;
    bottom: 10px;
    text-align: right;
}
.information-actions button {
    margin-left: 5px;
}

.print-global-label {}
.print-global-value {
    padding-left: 0.5cm;
}

.print .button-action {
    padding: 0;
    margin: 0;
    border: none;
    background-color: #fff;
}

.print .button-action .action-name {
    grid-column-end: span 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.25cm;
    border-radius: 0;
    padding: 0;
    color: #000;
}

.print .action-name-name {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    width: auto;
    width: initial;
    margin-top: 0;
}

.print .button-action-name {
    padding: 0 0.25cm 0 0;
    /*border-top: 1px solid #999;*/
    border-right: 1px solid #999;
    text-align: right;
    font-weight: 700;
}

.print .button-action-action {
    padding-left: 0.25cm;
}

.print .button-action-action > div {
    margin-bottom: 0.2cm;
}
.print .action-messages-grid {
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(5, auto);
    /*grid-column-gap: 10px;*/
    /*grid-row-gap: 5px;*/
    justify-content: start;
    /*background-color: #e8e8e8;*/
    /*padding: 5px;*/
    /*border-radius: 6px;*/
}

.print .action-messages-grid > * {
    padding: 0.1cm 0.25cm;
    border-bottom: 1px solid #ddd;
}

.right-align {
    text-align: right;
}

.print .action-messages-grid .h {
    font-size: 85%;
    border-bottom: 1px solid #aaa;
    margin-bottom: 4px;
}

.print .value-label {
    text-transform: lowercase;
}
.print .button-actions {
    margin-top: 0.25cm;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    grid-row-gap: 0.5cm;
}
.messages-summary.exp {
    margin-top: 0;
}

body.print {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.2;
    color: #000;
    margin: 20px 40px;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
}

.print #root {
    background-color: white;
}

.print-title {
    font-size: 20pt;
}

.print-title-help {
    color: #999;
    font-size: 10pt;
}

.print .panel-wrapper {
    padding: 0;
    border: none;
    background-color: white;
}

.print .panel-title {
    display: block;
    margin: 0.75cm 0 0.5cm 0;
    padding: 0;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #000;
    font-size: 1.5rem;
}

.print .content-wrapper {
    margin: 0;
}

@media print {

    @page {
        margin: 2cm 1cm;
    }

    body {
        margin: 0!important;
        padding: 0!important;
        font-size: 10pt;
    }

    .button-editor {
        page-break-inside: avoid;
    }

    .print-title {
        color: #000;
    }

    .print-title-help {
        display: none;
    }

    .panel-wrapper {
        background-color: white;
    }

}


/* default is 'light' */

html {
    --main-bg-color: white;
    --main-bg: white;
    --header-bg: #ffffff;
    --header-padding: 10px 0 5px 0;
    --header-border-top: 1px solid #000;
    --subheader-bg: #ffffff;
    --subheader-padding: 10px 0 0 0;
    --instruction-margin: 5px 0 0 0;
    --panel-bg: #f7f6f7;
    --panel-border-top: 2px solid #1e85be;
    --panel-font-weight: 400;
    --panel-font-color: #000;
    --link: #7070ff;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    /*--success: #28a745;*/
    --success: #2db94d;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --border-dark: #999;
    --border-light: #ddd;
    --border-dark-dark: #333;
    --border-dark-light: #555;

    --text: #000;
    --text-light: #606060;
    --text-extra-light: #808080;
    --text-tip: inherit;
    --text-tip-bg: yellow;
    --text-checked: #000;
    --panel-toggle-text: #222;
    --action-name-name-bg: #909090;
    --action-name-name-text: #ffffff;
    --button-action-border-top: 1px solid #888888 !important;
    --midi-port-bg: #e0e0e0;
    --midi-port-ok-bg: #ACDFAC;
}

html[data-theme='dark'] {
    --main-bg-color: #0f0f0f;
    --main-bg: #0f0f0f linear-gradient(#606060, #2f2f2f, #0f0f0f);
    --header-bg: #333333;
    --header-padding: 10px 10px 5px 10px;
    --header-border-top: 1px solid #777;
    --subheader-bg: #333333;
    --subheader-padding: 10px 10px 10px 10px;
    --instruction-margin: 5px 0 0 0;
    --panel-bg: #efefef;
    --panel-border-top: 2px solid #aaa;
    --panel-font-weight: 500;
    --panel-font-color: #2f2f2f;
    --link: #d0d0ff;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    /*--success: #28a745;*/
    --success: #2db94d;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --border-dark: #999;
    --border-light: #ddd;
    --border-dark-dark: #333;
    --border-dark-light: #555;

    --text: #000;
    --text-light: #606060;
    --text-extra-light: #808080;
    --text-tip: yellow;
    --text-tip-bg: inherit;
    --text-checked: #000;
    --panel-toggle-text: #222;
    --action-name-name-bg: #909090;
    --action-name-name-text: #ffffff;
    --button-action-border-top: 1px solid #888888 !important;
    --midi-port-bg: #e0e0e0;
    --midi-port-ok-bg: #ACDFAC;
}


html[data-theme='darkest'] select {
    border-color: #222831;
}

html[data-theme='darkest'] {

/*--main-bg: #111;*/
    /*--text: #fff;*/
    /*--title-text: #f8f8f8;*/
    /*--link: #6d6dff;*/
    /*--footer: #444;*/


    --main-bg-color: #111;
    --main-bg: #111;

    --header-bg: #222831;
    --header-padding: 10px 10px 5px 10px;
    /*--header-border-top: 1px solid #777;*/
    --header-border-top: none;
    --subheader-bg: #222831;
    --subheader-padding: 10px 10px 10px 10px;
    --instruction-margin: 5px 0 0 0;

    --panel-bg: #222831;
    /*--panel-border-top: 2px solid #aaa;*/
    --panel-border-top: none;
    --panel-font-weight: 500;

    --panel-font-color: #e0e0e0;

    --link: #d0d0ff;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    /*--success: #28a745;*/
    --success: #2db94d;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --border-dark: #999;
    --border-light: #ddd;
    --border-dark-dark: #333;
    --border-dark-light: #555;

    --text: #f0f0f0;
    --text-light: #a0a0af;
    --text-extra-light: #70707a;
    --text-checked: #fff;
    --panel-toggle-text: #eaeaef;

    --text-tip: yellow;
    --text-tip-bg: inherit;

    --action-name-name-bg: #a0a0af;
    --action-name-name-text: #000;
    --button-action-border-top: 1px solid #a0a0af !important;

    --midi-port-bg: #000;
    --midi-port-ok-bg: #000;
}


html,
body {
  height: 100%;
}

html {
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  color: var(--text);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-bg);;
}

html {
  background-color: var(--main-bg-color); /* because #root element may become larger thant body element */
}

#root {
  padding: 2px;
  max-width: 1200px;
  margin: 0 auto;
}

body.popup {
  background-color: #fff;
}

.popup #root {
  background-color: white;
}

:focus { outline: none; }

.b {
  font-weight: 500;
}

.bold {
  font-weight: 500;
}

.i {
  font-style: italic;
}

input, label {
  cursor: pointer;
}

@media print {

  button {
    display: none!important;
  }

}



/* based on: https://getbootstrap.com/docs/4.0/components/buttons/ */

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 5px 10px;
    font-size: 1rem;
    border-radius: 4px;
    -webkit-transition: -webkit-filter 150ms;
    transition: -webkit-filter 150ms;
    transition: filter 150ms;
    transition: filter 150ms, -webkit-filter 150ms;
}

.btn:hover {
    -webkit-filter: brightness(86%);
            filter: brightness(86%);
}

.btn:disabled {
    background-color: #e0e0e0!important;
    color: #999!important;
    border-color: #c0c0c0!important;
}

.btn:disabled:hover {
    -webkit-filter: none;
            filter: none;
}

.btn-large {
    line-height: 1.5;
    border-radius: 0.4rem;
}

.btn-small {
    padding: 0 5px 1px 5px;
    border-radius: .25rem;
    font-size: 13px;
}

.btn-x-small {
    padding: 1px 7px 2px 7px;
    border-radius: .25rem;
    font-size: 13px;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
}

.btn-primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn-secondary {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.btn-success {
    color: #fff;
    background-color: var(--green);
    border-color: var(--green);
}

.btn-danger {
    color: #fff;
    background-color: var(--red);
    border-color: var(--red);
}

.btn-warning {
    color: #212529;
    background-color: var(--yellow);
    border-color: var(--yellow);
}

.btn-info {
    color: #fff;
    background-color: var(--cyan);
    border-color: var(--cyan);
}

.btn-light {
    color: #212529;
    background-color: var(--light);
    border-color: var(--light);
}

.btn-dark {
    color: #fff;
    background-color: var(--dark);
    border-color: var(--dark);
}

.btn-white-border {
    border-color: #f4f4f4;
}

button, select {
    text-transform: none;
}

button, input {
    overflow: visible;
}

button, input, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    border-radius: 0;
}

.blinking {
    animation: blinkingText 1.2s infinite alternate-reverse;
}
@-webkit-keyframes blinkingText {

    0%   {
        box-shadow: 0 0 7px 3px rgba(234, 152, 69, 0.06);
    }
    100% {
        box-shadow: 0 0 7px 3px rgba(234, 152, 69, 1.0);
    }
}
@keyframes blinkingText {

    0%   {
        box-shadow: 0 0 7px 3px rgba(234, 152, 69, 0.06);
    }
    100% {
        box-shadow: 0 0 7px 3px rgba(234, 152, 69, 1.0);
    }
}

.width-100 {
    width: 100px;
}
.width-120 {
    width: 120px;
}
.width-200 {
    width: 200px;
}

input[type=range].slider {
    -webkit-appearance: none;
}
input[type=range].slider:focus {
    outline: none;
}
input[type=range].slider::-webkit-slider-runnable-track {
    /*width: 100%;*/
    height: 10px;
    cursor: pointer;
    background: #fff;
    border: 1px solid #999;
}
input[type=range].slider::-webkit-slider-thumb {
    /*box-shadow: 0 0 1px #670000, 0 0 0 #810000;*/
    border: 1px solid #333;
    height: 14px;
    width: 15px;
    border-radius: 3px;
    background: #aaa;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3px;
}
input[type=range].slider:focus::-webkit-slider-runnable-track {
    background: #fff;
}

body.popup {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.4;
    color: #000;
    margin: 20px 40px;
    /*margin: 0 40px;*/
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
}

.help-wrapper {
    margin-bottom: 40px;
}

.help-wrapper .panel-title button {
    margin-left: 20px;
}

.help-top .help {
    max-height: 40vh;
    overflow-y: scroll;
}

.help-left .help,
.help-right .help {
    max-height: calc(100vh - 50px - 31px);
    overflow-y: scroll;
}

.help h2 {
    font-size: 1.25rem;
    margin: 15px 0 10px 0;
    padding-bottom: 3px;
    border-bottom: 1px solid #999;
}

.help h3 {
    font-size: 1rem;
    margin: 10px 0 10px 0;
}

.help ol,
.help ul {
    margin: 0;
}

.help li {
    margin-bottom: 4px;
}

.help > div {
    margin-bottom: 10px;
}

ol.space-above,
ul.space-above {
    margin-top: 5px;
}

li > div {
    margin-bottom: 5px;
}

.help .msg-types-grid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    justify-items: start;
    align-items: start;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
}

.help .msg-types-grid > div {
}

.help .msg-type-name {
    font-weight: bold;
}

.help .msg-type-description {
}


