.information {
    margin: 10px 0 0 0;
    padding: 10px 20px;
    border-top: var(--panel-border-top);
    position: relative;     /* for button positioning */
    border: 1px solid #aaa;
    background-color: #ffffaa;
    color: #333333;
    line-height: 150%;
}

.information .sub-info {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--text-extra-light);
    font-size: 13px;
    text-align: right;
}

.information .important {
    font-weight: bold;
    margin-bottom: 5px;
}

.information ol,
.information ul {
    margin: 0;
    margin-bottom: 5px;
}

.information li {
    margin-top: 2px;
}

.information-actions {
    position: absolute;
    right: 20px;
    bottom: 10px;
    text-align: right;
}
.information-actions button {
    margin-left: 5px;
}
