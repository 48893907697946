
.ports-grid {
    display: flex;
    flex-direction: row;
}

.port {
    margin-right: 10px;
    padding: 6px 4px 5px 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--midi-port-bg);
    font-size: 13px;
    color: var(--text-light);
}

.port > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.port-name {
    font-weight: 500;
}

.port-switch {
    margin-left: 4px;
}

.react-switch {
    vertical-align: middle;
}

.react-switch-bg {
    background-color: rgb(136, 136, 136)!important;
}

.port.device-ok {
    background-color: var(--midi-port-ok-bg);
    color: var(--text);
}

.device-ok .react-switch-bg {
    background-color: green!important;
}
