.print .action-messages-grid {
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(5, auto);
    /*grid-column-gap: 10px;*/
    /*grid-row-gap: 5px;*/
    justify-content: start;
    /*background-color: #e8e8e8;*/
    /*padding: 5px;*/
    /*border-radius: 6px;*/
}

.print .action-messages-grid > * {
    padding: 0.1cm 0.25cm;
    border-bottom: 1px solid #ddd;
}

.right-align {
    text-align: right;
}

.print .action-messages-grid .h {
    font-size: 85%;
    border-bottom: 1px solid #aaa;
    margin-bottom: 4px;
}

.print .value-label {
    text-transform: lowercase;
}