/* default is 'light' */

html {
    --main-bg-color: white;
    --main-bg: white;
    --header-bg: #ffffff;
    --header-padding: 10px 0 5px 0;
    --header-border-top: 1px solid #000;
    --subheader-bg: #ffffff;
    --subheader-padding: 10px 0 0 0;
    --instruction-margin: 5px 0 0 0;
    --panel-bg: #f7f6f7;
    --panel-border-top: 2px solid #1e85be;
    --panel-font-weight: 400;
    --panel-font-color: #000;
    --link: #7070ff;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    /*--success: #28a745;*/
    --success: #2db94d;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --border-dark: #999;
    --border-light: #ddd;
    --border-dark-dark: #333;
    --border-dark-light: #555;

    --text: #000;
    --text-light: #606060;
    --text-extra-light: #808080;
    --text-tip: inherit;
    --text-tip-bg: yellow;
    --text-checked: #000;
    --panel-toggle-text: #222;
    --action-name-name-bg: #909090;
    --action-name-name-text: #ffffff;
    --button-action-border-top: 1px solid #888888 !important;
    --midi-port-bg: #e0e0e0;
    --midi-port-ok-bg: #ACDFAC;
}

html[data-theme='dark'] {
    --main-bg-color: #0f0f0f;
    --main-bg: #0f0f0f linear-gradient(#606060, #2f2f2f, #0f0f0f);
    --header-bg: #333333;
    --header-padding: 10px 10px 5px 10px;
    --header-border-top: 1px solid #777;
    --subheader-bg: #333333;
    --subheader-padding: 10px 10px 10px 10px;
    --instruction-margin: 5px 0 0 0;
    --panel-bg: #efefef;
    --panel-border-top: 2px solid #aaa;
    --panel-font-weight: 500;
    --panel-font-color: #2f2f2f;
    --link: #d0d0ff;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    /*--success: #28a745;*/
    --success: #2db94d;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --border-dark: #999;
    --border-light: #ddd;
    --border-dark-dark: #333;
    --border-dark-light: #555;

    --text: #000;
    --text-light: #606060;
    --text-extra-light: #808080;
    --text-tip: yellow;
    --text-tip-bg: inherit;
    --text-checked: #000;
    --panel-toggle-text: #222;
    --action-name-name-bg: #909090;
    --action-name-name-text: #ffffff;
    --button-action-border-top: 1px solid #888888 !important;
    --midi-port-bg: #e0e0e0;
    --midi-port-ok-bg: #ACDFAC;
}


html[data-theme='darkest'] select {
    border-color: #222831;
}

html[data-theme='darkest'] {

/*--main-bg: #111;*/
    /*--text: #fff;*/
    /*--title-text: #f8f8f8;*/
    /*--link: #6d6dff;*/
    /*--footer: #444;*/


    --main-bg-color: #111;
    --main-bg: #111;

    --header-bg: #222831;
    --header-padding: 10px 10px 5px 10px;
    /*--header-border-top: 1px solid #777;*/
    --header-border-top: none;
    --subheader-bg: #222831;
    --subheader-padding: 10px 10px 10px 10px;
    --instruction-margin: 5px 0 0 0;

    --panel-bg: #222831;
    /*--panel-border-top: 2px solid #aaa;*/
    --panel-border-top: none;
    --panel-font-weight: 500;

    --panel-font-color: #e0e0e0;

    --link: #d0d0ff;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    /*--success: #28a745;*/
    --success: #2db94d;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --border-dark: #999;
    --border-light: #ddd;
    --border-dark-dark: #333;
    --border-dark-light: #555;

    --text: #f0f0f0;
    --text-light: #a0a0af;
    --text-extra-light: #70707a;
    --text-checked: #fff;
    --panel-toggle-text: #eaeaef;

    --text-tip: yellow;
    --text-tip-bg: inherit;

    --action-name-name-bg: #a0a0af;
    --action-name-name-text: #000;
    --button-action-border-top: 1px solid #a0a0af !important;

    --midi-port-bg: #000;
    --midi-port-ok-bg: #000;
}
