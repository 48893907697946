.index {
    text-align: right;
}

.edit-action-msgs {
    margin: 10px 0;
}

.edit-action-msgs table {
    border-collapse: collapse;
}

.edit-action-msgs table th,
.edit-action-msgs table td {
    border: 1px solid #aaa;
}

.edit-grid {
    /*margin-top: 5px;*/
    display: grid;
    grid-template-columns: repeat(11, auto);
    grid-column-gap: 10px;
    grid-row-gap: 3px;
    justify-content: start;
    /*background-color: #e8e8e8;*/
    padding: 5px;
    border-radius: 6px;
}

.edit-grid .h {
    /*
    font-size: 85%;
    border-bottom: 1px solid #777;
    margin-bottom: 4px;
    */
    color: var(--text-light);
    font-size: 12px;
    border-bottom: 1px solid #888888;
    margin-bottom: 4px;
}

.edit-value-label {
    font-size: 85%;
    margin-right: 10px;
    white-space: nowrap;
}

.edit-value-label.min-space {
    margin-right: 5px;
}

.edit-value-value {
    font-size: 85%;
}

.edit-grid input[type="text"] {
    width: 30px;
}

.edit-data {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.self-align-center {
    align-self: center;
}

select.pcstyle,
select.ccstyle,
select.notes {
    margin-right: 10px;
}

.edit-grid input[type="range"] {
    margin-right: 5px;
}
