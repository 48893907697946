
.button-action {
    /*
    padding: 10px 10px 10px 10px;
    margin: 10px 10px 10px 0;
    border-left: 10px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    background-color: #f0f0f0;
    */
    margin-top: 10px;
    border-top: var(--button-action-border-top);
    position: relative;
}

.button-action.last {
    margin-bottom: 0;
}

/*
.button-action.ignored {
    border-color: #e8e8e8;
}
!*0: "off",*!
.button-action.color-0 {
    border-color: #d8d8d8;
}
!*1: "red",*!
.button-action.color-1 {
    border-color: #ff4444;
}
!*2: "green",*!
.button-action.color-2 {
    border-color: green;
}
!*3: "blue",*!
.button-action.color-3 {
    border-color: #8282ff;
}
!*4: "orange",*!
.button-action.color-4 {
    border-color: orange;
}
!*5: "yellow",*!
.button-action.color-5 {
    border-color: yellow;
}
!*6: "cyan",*!
.button-action.color-6 {
    border-color: cyan;
}
!*7: "violet",*!
.button-action.color-7 {
    border-color: violet;
}
!*8: "white"*!
.button-action.color-8 {
    border-color: white;
}
*/

.action-grid-0,     /* tap */
.action-grid-1,     /* hold */
.action-grid-2 { /* long-hold */
    display: grid;
    grid-template-columns: 145px auto;
    align-items: baseline;
}

.action-grid-0 {    /* tap */
    grid-template-rows: repeat(3, auto);
}

.action-grid-1,     /* hold */
.action-grid-2 {    /* long-hold */
    grid-template-rows: repeat(4, auto);
}

.span-2 {
    grid-column-end: span 2;
}

.action-grid-0.edit .action-row,
.action-grid-1.edit .action-row,
.action-grid-2.edit .action-row {
    padding: 2px 0;
}

.action-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 23px;
    /*padding: 2px 0;*/
}

.edit .action-row.action-with-select {
    /*padding: 0;*/
}

.action-row > div {
    margin-right: 5px;
}

.button-action .action-name {
    /*
    grid-column-end: span 2;
    display: grid;
    grid-template-columns: 140px auto 200px;
    margin-bottom: 15px;
    color: #000;
    */
    grid-column-end: span 2;
    margin: 0 0 5px 0;
    display: flex;
    flex-direction: row;
    color: #000;

}

/*
.action-wrapper {
    position: relative;
    border-top: 1px solid #888888;
}
*/

.action-edit-button {
    position: absolute;
    top: 5px;
    right: 0;
}


.action-name-name {
    /*
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 5px;
    padding-top: 2px;
    */
    width: 150px;
    padding: 3px 5px 2px 5px;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--action-name-name-text);
    background-color: var(--action-name-name-bg);
    display: flex;
    align-items: center;

    margin-top: -1px;

}

/*
.instruction {
    color: var(--text-light);
    font-size: 12px;
}
*/

.action-name .instruction {
    /*
    padding-top: 0.35rem;
    padding-bottom: 1px;
    margin-right: 10px;
    padding-left: 5px;
    */
    margin-right: 10px;
    padding-left: 5px;
    display: flex;
    align-items: center;
}



/*
.button-action.color-0 .action-name-name,   !*0: "off",*!
.button-action.color-0 .instruction {
    background-color: #d8d8d8;
}
.button-action.color-1 .action-name-name,   !*1: "red",*!
.button-action.color-1 .instruction {
    background-color: #ff4444;
    color: #fff;
}
.button-action.color-2 .action-name-name,   !*2: "green",*!
.button-action.color-2 .instruction {
    background-color: green;
    color: #fff;
}
.button-action.color-3 .action-name-name,   !*3: "blue",*!
.button-action.color-3 .instruction {
    background-color: #8282ff;
    color: #fff;
}
.button-action.color-4 .action-name-name,   !*4: "orange",*!
.button-action.color-4 .instruction {
    background-color: orange;
}
.button-action.color-5 .action-name-name,   !*5: "yellow",*!
.button-action.color-5 .instruction {
    background-color: yellow;
}
.button-action.color-6 .action-name-name,   !*6: "cyan",*!
.button-action.color-6 .instruction {
    background-color: cyan;
}
.button-action.color-7 .action-name-name,   !*7: "violet",*!
.button-action.color-7 .instruction {
    background-color: violet;
}
.button-action.color-8 .action-name-name,   !*8: "white"*!
.button-action.color-8 .instruction {
    background-color: white;
}
*/



/*
.button-action.color-0 .action-name-name {   !*0: "off",*!
    background-color: #909090;
}
.button-action.color-1 .action-name-name {   !*1: "red",*!
    background-color: #ff4444;
    color: #fff;
}
.button-action.color-2 .action-name-name {   !*2: "green",*!
    background-color: green;
    color: #fff;
}
.button-action.color-3 .action-name-name {   !*3: "blue",*!
    background-color: #8282ff;
    color: #fff;
}
.button-action.color-4 .action-name-name {   !*4: "orange",*!
    background-color: orange;
}
.button-action.color-5 .action-name-name {   !*5: "yellow",*!
    background-color: yellow;
    color: #000;
}
.button-action.color-6 .action-name-name {   !*6: "cyan",*!
    background-color: cyan;
    color: #000;
}
.button-action.color-7 .action-name-name {   !*7: "violet",*!
    background-color: violet;
}
.button-action.color-8 .action-name-name {   !*8: "white"*!
    background-color: white;
    color: #000;
}
*/



span.led-color {
    margin-left: 5px;
    padding: 0 5px;
}
.button-action.color-0 span.led-color {   /*0: "off",*/
    background-color: inherit;
    padding: 0;
}
select.led-color {
    margin-left: 5px;
}
.button-action.color-0 select.led-color {   /*0: "off",*/
    background-color: white;
}
.button-action.color-1 .led-color {   /*1: "red",*/
    background-color: #ff4444;
    color: white;
}
.button-action.color-2 .led-color {   /*2: "green",*/
    background-color: green;
    color: white;
}
.button-action.color-3 .led-color {   /*3: "blue",*/
    background-color: #8282ff;
    color: white;
}
.button-action.color-4 .led-color {   /*4: "orange",*/
    background-color: orange;
}
.button-action.color-5 .led-color {   /*5: "yellow",*/
    background-color: yellow;
    color: #000;
}
.button-action.color-6 .led-color {   /*6: "cyan",*/
    background-color: cyan;
    color: #000;
}
.button-action.color-7 .led-color {   /*7: "violet",*/
    background-color: violet;
}
.button-action.color-8 .led-color {   /*8: "white"*/
    background-color: white;
    color: #000;
}







.button-action.disabled .action-name-name {
    color: #aaa;
}
.button-action .action-name.ignored {
    background-color: #e8e8e8;
}

/*
.button-action .action-name.color-0 {
    background-color: #d8d8d8;
}

.button-action .action-name.color-1 {
    background-color: #ff4444;
    color: #fff;
}
.button-action .action-name.color-1 .instruction {
    color: #eee;
}
.action-name.color-1 > button {
     border-color: #fff;
}

.button-action .action-name.color-2 {
    background-color: green;
    color: #fff;
}
.button-action .action-name.color-2 .instruction {
    color: #eee;
}
.action-name.color-2 > button {
    border-color: #fff;
}

.button-action .action-name.color-3 {
    background-color: #8282ff;
    color: #fff;
}
.button-action .action-name.color-3 .instruction {
    color: #eee;
}
.action-name.color-3 > button {
    border-color: #fff;
}

.button-action .action-name.color-4 {
    background-color: orange;
}

.button-action .action-name.color-5 {
    background-color: yellow;
}

.button-action .action-name.color-6 {
    background-color: cyan;
}

.button-action .action-name.color-7 {
    background-color: violet;
}

.button-action .action-name.color-8 {
    background-color: white;
}

.button-action .action-name button {
    margin-left: 10px;
}
*/

/*
.button-action .action-meta {
    margin-bottom: 15px;
    padding-bottom: 3px;
    border-bottom: 1px solid #999999;
}
*/

.dim {
    color: #777;
}
