.messages-summary {
    margin-top: 5px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: start;
    grid-row-gap: 3px;
}

.msg-number {
    text-align: right;
    color: #555;
    margin-right: 10px;
}

.msg-type {
    margin-right: 10px;
    font-weight: 500;
}

.msg-description {
    display: flex;
}

.msg-channel {
    margin-right: 10px;
}

.msg-description > div {
    padding: 0 10px 0 0;
}

.message-summary > div {
    margin-right: 5px;
}

.action-message {
    display: flex;
    flex-direction: row;
    margin: 0 0 5px 0;
}

.action-message > div {
    margin-right: 10px;
}
