
.width-100 {
    width: 100px;
}
.width-120 {
    width: 120px;
}
.width-200 {
    width: 200px;
}

input[type=range].slider {
    -webkit-appearance: none;
}
input[type=range].slider:focus {
    outline: none;
}
input[type=range].slider::-webkit-slider-runnable-track {
    /*width: 100%;*/
    height: 10px;
    cursor: pointer;
    background: #fff;
    border: 1px solid #999;
}
input[type=range].slider::-webkit-slider-thumb {
    /*box-shadow: 0 0 1px #670000, 0 0 0 #810000;*/
    border: 1px solid #333;
    height: 14px;
    width: 15px;
    border-radius: 3px;
    background: #aaa;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3px;
}
input[type=range].slider:focus::-webkit-slider-runnable-track {
    background: #fff;
}
