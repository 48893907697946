.print .button-action {
    padding: 0;
    margin: 0;
    border: none;
    background-color: #fff;
}

.print .button-action .action-name {
    grid-column-end: span 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.25cm;
    border-radius: 0;
    padding: 0;
    color: #000;
}

.print .action-name-name {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    width: initial;
    margin-top: 0;
}

.print .button-action-name {
    padding: 0 0.25cm 0 0;
    /*border-top: 1px solid #999;*/
    border-right: 1px solid #999;
    text-align: right;
    font-weight: 700;
}

.print .button-action-action {
    padding-left: 0.25cm;
}

.print .button-action-action > div {
    margin-bottom: 0.2cm;
}