
.subheader {
    background-color: var(--subheader-bg);
    padding: var(--subheader-padding);
    display: flex;
    flex-direction: row;
    position: relative;
}

.subheader .error {
    font-size: 13px;
    display: flex;
    flex-direction: row;
}
.subheader .error > div {
    background-color: #ff6258;
    padding: 4px 5px 2px 5px;
}

.progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    /*background-color: yellow;*/
    color: red;
}

/*
.progress-bar.reading {
    background-color: var(--yellow);
}
.progress-bar.writing {
    background-color: var(--red);
}
*/

.subheader-left {
    flex-grow: 2;
}

.subheader-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.subheader .instruction {
    font-size: 13px;
    font-weight: 500;
    margin: var(--instruction-margin);
    /*color: #b2b2b2;*/
    color: var(--text-light);
}

.subheader .tip {
    font-size: 13px;
    font-weight: 500;
    margin: var(--instruction-margin);
    color: var(--text-tip);
    background-color: var(--text-tip-bg);
}

.no-midi {
    margin: 0;
    border: 8px solid #e0e0e0;
    padding: 4px;
    background-color: #fff900;
    color: #000;
    text-align: center;
}

@media print {
    .subheader {
        display: none;
    }
}

